import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import PaymentReportTable from "./PaymentReportTable";
import { useGetPaymentsMutation } from "../../../../../services/invoice.service";
import dayjs from "dayjs";
import { exportToExcel } from "../../../../../utils";

const PaymentReport = () => {
  const [getReports, {data, isLoading}] = useGetPaymentsMutation();
  const [payments, setPayments] = useState([]);

  const [showFilter, toggleFilter] = useState(null);
  const [doExport, setExport] = useState(false);

  const [filterData, setFilterData] = useState({
    startDate: dayjs().startOf('M').toDate(),
    endDate: dayjs().toDate(),
    export: false
  })

  useEffect(() => {
    getReports(filterData);
  }, [])


  useEffect(() => {
    if (data && !doExport) { 
      setPayments(data);
    }

    if (data && doExport) {
      exportToExcel(data, `PPS Payments for ${dayjs(filterData.startDate).format('YYYY-MM-DD')}-${dayjs(filterData.endDate).format('YYYY-MM-DD')}`);
      setExport(false)
      setFilterData({...filterData, export: false})
    }
  }, [data]);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Payments Report"
          title="Dashboard"
          subtitle="Payments Report"
          canRefresh={true}
          canFilter={true}
          canExport={true}
          showFilter={showFilter}
          onRefresh={() => getReports(filterData)}
          toggleFilter={() => toggleFilter(!showFilter)}
          doExport={() => {
            setExport(true);
            getReports({...filterData, export: true});
          }}
        />

        {showFilter && 
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">

              <div className="cal-icon focused ">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.startDate}
                  onChange={(date) => setFilterData({...filterData, startDate: date})}
                  dateFormat="dd-MM-yyyy"
                  // maxDate={filterData.endDate}
                />
              </div>
              <label className="focus-label">From</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">

              <div className="cal-icon">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.endDate}
                  onChange={(date) => setFilterData({...filterData, endDate: date})}                  
                  dateFormat="dd-MM-yyyy"
                  // maxDate={new Date()}
                />
              </div>
              <label className="focus-label">To</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <Link 
              to="#" 
              onClick={() => getReports(filterData)} 
              className="btn btn-success btn-block w-100">
              {" "}
              Search{" "}
            </Link>
          </div>
        </div>}

        <PaymentReportTable data={payments} loading={isLoading} />
      </div>
    </div>
  );
};

export default PaymentReport;
