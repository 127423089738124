import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import "react-datepicker/dist/react-datepicker.css";
import InvoiceTable from "./invoiceTable";
import { useGetInvoicesMutation } from "../../../../../services/invoice.service";
import dayjs from "dayjs";
import TicketFilter from "../../../../../components/TicketFilter";
import { exportToExcel, formatNumber } from "../../../../../utils";
import { useSelector } from "react-redux";
import ViewGenerators from "../../../../../components/modelpopup/ViewGenerators";
import ViewBookingCustomers from "../../../../../components/modelpopup/ViewBookingCustomersModal";
import { Link } from "react-router-dom";
import ViewInvoicePaidListModal from "../../../../../components/modelpopup/ViewInvoicePaidListModal";

const Invoices = () => {
  const [fetchRecords, {data, isLoading}] = useGetInvoicesMutation();
  const [invoices, setInvoices] = useState([]);
  const [showFilter, toggleFilter] = useState(null);
  const [doExport, setExport] = useState(false);
  const [viewType, setViewType] = useState(null);

  const [filterData, setFilterData] = useState({
    companyName: '',
    status: '',
    generator: '',
    startDate: dayjs().startOf('M').toDate(),
    endDate: dayjs().toDate(),
    type: 'history',
    page: 1,
    export: false,
    customerType: 'count',

  })

  useEffect(() => {
    fetchRecords(filterData);
  }, []);

  useEffect(() => {
    if (data && !doExport) { 
      setInvoices(data.invoices);
    }

    if (data && doExport) {
      exportToExcel(data, `PPS Invoices ${dayjs(filterData.startDate).format('YYYY-MM-DD')}-${dayjs(filterData.endDate).format('YYYY-MM-DD')}`);
      setExport(false)
      setFilterData({...filterData, export: false})
    }
  }, [data]);

  const onChange = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: value
    })
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Invoices"
            title="Dashboard"
            subtitle="Invoices"
            canRefresh={true}
            canExport={true}
            canFilter={true}
            showFilter={showFilter}
            onRefresh={() => fetchRecords(filterData)}
            toggleFilter={() => toggleFilter(!showFilter)}
            doExport={() => {
              setExport(true);
              fetchRecords({...filterData, export: true});
            }}
          />

          <div class="row">
            <Link  
              to="#" class="col-md-3 d-flex"
              onClick={() => setViewType('paid')}
              data-bs-toggle="modal"
              data-bs-target="#invoice_list"
            >
              <div class="stats-info w-100 mb-0">
                <h6>Total Paid</h6>
                <h4>{formatNumber(data?.totalPaid) || 0}</h4>
              </div>
            </Link>
            <Link 
              to="#" class="col-md-3 d-flex"
              data-bs-toggle="modal"
              data-bs-target="#invoice_list"
              onClick={() => setViewType('outstanding')}
            >
              <div class="stats-info w-100 mb-0">
                <h6>Outstanding</h6>
                <h4>{formatNumber(data?.totalOutstanding) || 0}</h4>
              </div>
            </Link>
            <Link 
              class="col-md-3 d-flex"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#customers_list"
            >
              <div class="stats-info w-100 mb-0">
                <h6>Clients</h6>
                <h4>{data?.clients?.length || 0}</h4>
              </div>
            </Link>
            <Link 
              class="col-md-3 d-flex"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#generator_details"
            >
              <div class="stats-info w-100 mb-0">
                <h6>Generators</h6>
                <h4>{data?.generators?.length || 0}</h4>
              </div>
            </Link>
          </div>

          <hr />
          {showFilter &&
            <TicketFilter onChange={onChange} filterData={filterData} doSearch={() => fetchRecords(filterData)} type="history" />
          }
          <InvoiceTable data={invoices} loading={isLoading} />
        </div>
      </div>
      <ViewGenerators 
        generators={data?.generators} 
        isInvoice={true}
        closeGen={() => console.log('close')}
      />
      <ViewBookingCustomers 
        customers={data?.clients} 
        type="invoice"
        close={() => {
          console.log('close clients')
        }} 
      />
      <ViewInvoicePaidListModal 
        invoices={viewType === 'paid' ? data?.totalPaidRows : data?.totalOutstandingRows} 
        type={viewType}
        close={() => {
          console.log('close clients')
        }} 
      />
    </>
  );
};

export default Invoices;
