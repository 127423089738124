import React from "react";
import { formatNumber } from "../../utils";

const ViewBookingCustomers = ({customers, close, type}) => {
  return (
    <>
      <div>
        <div
          id="customers_list"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Customers List</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  {type === 'invoice' ? 
                    <table className="table">
                      <thead>
                        <tr>
                        <th>Company Name</th>
                        <th>Total Invoice</th>
                        <th>Total Amount</th>
                        <th>Amount Paid</th>
                        <th>Outstanding</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers?.map(customer => (
                          <tr key={customer.id}>
                            <td>{customer.company_name}</td>
                            <td className="text-center">{customer.total_invoice}</td>
                            <td>{formatNumber(customer.amount)}</td>
                            <td>{formatNumber(customer.amount - customer.outstanding_balance)}</td>
                            <td>{formatNumber(customer.outstanding_balance)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  :
                    <table className="table">
                      <thead>
                        <tr>
                        <th>Company Name</th>
                        <th>Total Bookings</th>
                        <th>Contact Person</th>
                        <th>Phone No.</th>
                        <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers?.map(customer => (
                          <tr key={customer.id}>
                            <td>{customer.company_name}</td>
                            <td className="text-center">{customer.total_bookings}</td>
                            <td>{customer.contact_name}</td>
                            <td>{customer.phone_no}</td>
                            <td>{customer.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBookingCustomers;
