import React, { useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { formatNumber, pad } from "../../../../../utils";
import dayjs from "dayjs";
import { FileText } from "react-feather";
import RecordPayment from "../../../../../components/modelpopup/RecordPayment";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../../redux/slices/ui.slice";
import { DELETE_INVOICE } from "../../../../../services/constants";

const InvoiceTable = ({data, loading}) => {
  const [invoice, setInvoice] = useState(null);
  const dispatch = useDispatch();

  const downloadPDF = (invoiceId) => {
    const url = `${process.env.REACT_APP_API_URL}/invoices/pdf/${invoiceId}?type=download`;
    window.open(url);
  }

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Invoice',
        url: `${DELETE_INVOICE}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // sorter: (a, b, i) => a.id.length - b.id.length,
      render: (t, r, i) => i + 1
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_id",
      render: (text,r) => <Link to={`/invoice/${r.id}`}>#{pad(r.invoice_id, 4)}</Link>,
      sorter: (a, b) => a.invoice_id.length - b.invoice_id.length,
    },
    {
      title: "Booking ID",
      dataIndex: "booking",
      render: (t, r) => (
        <Link
          onClick={() => localStorage.setItem("minheight", "true")}
          to={`/booking/${r.booking.id}`}
        >
          #PPS-{pad(r.booking.booking_id, 4)}
        </Link>
      )
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client.length - b.client.length,
      render: (t, r) => 
        <Link to={`/customer/${r.customer.id}`}>{r.customer?.company_name}</Link>
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      sorter: (a, b) => a.eventDate.length - b.eventDate.length,
      render: (t, r) => dayjs(r.eventDate).format('DD MMM YYYY')
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (text) => <span> {formatNumber(text)}</span>,
      sorter: (a, b) => a.total.length - b.total.length,
    },
    {
      title: "Outstanding",
      dataIndex: "outstanding_balance",
      render: (text) => <span> {formatNumber(text)}</span>,
      sorter: (a, b) => a.outstanding_balance.length - b.outstanding_balance.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={
            text === "PAID"
              ? "badge bg-success"
              : text === "PARTIAL"
              ? "badge bg-inverse-info"
              : text === "PENDING"
              ? "badge bg-warning"
              : text === "FREE"
              ? "badge bg-info"
              : "badge bg-danger"
          }>
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.issueDate.length - b.issueDate.length,
      render: (t, r) => dayjs(r.createdAt).format('DD MMM YYYY')
    },
    {
      title: "Action",
      sorter: true,
      className: "text-end",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => setInvoice(r)}
              data-bs-toggle="modal"
              data-bs-target="#record_payment"
            >
              <FileText
                className="feather-file-text m-r-5"
                size={16}
              />
                Record Payment
            </Link>
            
            <Link className="dropdown-item" to={`/invoice/${r.id}/edit`}>
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link className="dropdown-item" to={`/invoice/${r.id}`}>
              <i className="fa fa-eye m-r-5" /> View
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => downloadPDF(r.id)}>
              <i className="fa fa-file-pdf m-r-5" /> Download
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey={(record) => record.id}
            pagination={false}
            className="table table-striped custom-table mb-0"
          />
        </div>
      </div>
      <RecordPayment invoice={invoice} closeModal={() => setInvoice(null)} />
    </div>
  );
};

export default InvoiceTable;
