import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useCreateInvoiceMutation } from "../../services/invoice.service";
import { formatNumber } from "../../utils";
import alertify from "alertifyjs";
import environmentConfig from "../../_configs/environment.config";

const vatPerc = 7.5;

const GenerateInvoiceModal = ({booking, closeGen}) => {
  const closeRef = useRef();
  const [saveInvoice, { data: invoiceData, isLoading, isSuccess, isError }] = useCreateInvoiceMutation();
  const [formState, setFormState] = useState({
    total: 0,
    amount: 0,
    amountToPay: 0,
    amountPaid: 0,
    // prepaidBalance: 0,
    discount: 0,
    vat: 0,
    id: '',
    other_info: '',
    items: [
    {
      description: "",
      quantity: "",
      unit_cost: "",
      amount: "",
      discount: "",
      taxable: false
    },
  ]});

  useEffect(() => {
    let total = 0, amount = 0, discount = 0, vat= 0, items = [], id = '';
    if (booking) {
      id = booking.id;
      
      booking.generators.forEach(gen => {
        amount += amount + parseFloat(gen.amount)
        items.push({
          amount: gen.amount,
          description: gen.description,
          unit_cost: gen.amount,
          quantity: gen.quantity ?? 1,
          discount: "",
          taxable: false
        })
      });

      items.push({
        amount: 0,
        description: 'Logistics',
        unit_cost: 0,
        quantity: 1,
        discount: "",
        taxable: false
      }, {
        amount: 0,
        description: 'Installation & Tech Support',
        unit_cost: 0,
        quantity: 1,
        discount: "",
        taxable: false
      },{
        amount: 0,
        description: 'Amoured Cable (complementary)',
        unit_cost: 0,
        quantity: 1,
        discount: "",
        taxable: false
      })
      vat = amount * 0 / 100;
      total = vat + amount;
      setFormState({...formState, amount, total, id, vat, discount, items});
    }
  }, [booking])

  const addRow = () => {
    const newRow = {
      description: "",
      quantity: 1,
      unit_cost: "",
      amount: "",
      discount: "",
      taxable: false
    };
    setFormState({...formState, items: [...formState.items, newRow]});
  };

  const removeRow = (index) => {
    let total = 0, amount = 0, vat = 0;
    
    const items = [...formState.items]

    items.splice(index, 1);

    items.forEach(item => {
      amount += item.amount
      if (item.taxable) {
        vat += item.amount * environmentConfig.tax / 100;
      }
    });
    total = parseFloat(amount) + parseFloat(vat);

    setFormState({...formState, amount, total, vat, items});
  };

  const submit = async () => {
    if (formState.total > 0) {
      await saveInvoice(formState).then(res => {
        const saveData = res.data
        if (saveData?.success ) {  
          alertify.success("Saved successfully");
          closeGen();
          closeRef.current.click()
        } else {
          alertify.error(saveData?.message);
        }
      });    

      if(isError) alertify.error('Unable to generate invoice')
    }
  }

  return (
    <>
      <div>
        {/* Generate Invoice Modal */}
        <div
          id="generate_invoice"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Generate Invoice</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                  onClick={closeGen}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-md-12 col-sm-12 p-0">
                    <div className="table-responsive">
                      <table
                        className="table table-hover table-white"
                        id="addTable"
                      >
                        <thead>
                          <tr>
                            <th>Taxable</th>
                            <th className="col-md-6">Description</th>
                            <th className="col-md-2">Unit Cost</th>
                            <th>Qty</th>
                            <th className="">Dis (%)</th>
                            <th className="col-md-3">Amount</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody className="tbodyone">
                          
                          {formState.items.map((row, i) => (
                            <tr key={`invoice-item-${i}`}>
                              <td className="text-center">
                                <label className="custom_check">
                                  <input 
                                    type="checkbox" 
                                    value={row.taxable} 
                                    onChange={(e) => {
                                      const items = [...formState.items];
                                      items[i].taxable = e.target.checked;
                                      let total = 0, amount = 0, vat = 0;

                                      if (e.target.checked) {
                                        if (items[i].amount > 0){
                                          vat = items[i].amount * environmentConfig.tax / 100;

                                          items.forEach(item => {
                                            amount += item.amount
                                            if (item.taxable) {
                                              vat += item.amount * environmentConfig.tax / 100;
                                            }
                                          });
                                          
                                        }

                                      } else {
                                        items.forEach(item => {
                                          amount += item.amount
                                          if (item.taxable) {
                                            vat += item.amount * environmentConfig.tax / 100;
                                          }
                                        });
                                      }

                                      total = parseFloat(vat) + parseFloat(amount);
                                      
                                      setFormState({...formState, total, vat, amount});
                                    }} 
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={row.description}
                                  onChange={(e) => {
                                    const newForm = {...formState};
                                    newForm.items[i].description = e.target.value;

                                    setFormState(newForm);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={row.unit_cost}
                                  onChange={(e) => {
                                    let total = 0, amount = 0, vat = 0;
                                    const items = [...formState.items];

                                    if (e.target.value !== '') {
                                      items[i].unit_cost = e.target.value;
                                      items[i].amount = items[i].quantity * e.target.value;                            
                                    } else {
                                      items[i].unit_cost = e.target.value;
                                      items[i].amount = 0;
                                    }

                                    items.forEach(item => {
                                      amount += item.amount
                                      if (item.taxable) {
                                        vat += item.amount * environmentConfig.tax / 100;
                                      }
                                    });
                                    
                                    total = parseFloat(vat) + parseFloat(amount);
            
                                    setFormState({...formState, amount, total, vat, items});
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={row.quantity}
                                  onChange={(e) => {
                                    let total = 0, amount = 0, vat = 0;
                                    const items = [...formState.items];

                                    if (e.target.value !== '') {
                                      items[i].quantity = e.target.value;
                                      items[i].amount = items[i].unit_cost * e.target.value;                            
                                    } else {
                                      items[i].quantity = e.target.value;
                                      items[i].amount = 0;
                                    }
                                    items.forEach(item => {
                                      amount += item.amount
                                      if (item.taxable) {
                                        vat += item.amount * environmentConfig.tax / 100;
                                      }
                                    });

                                    total = parseFloat(vat) + parseFloat(amount);
                                   
                                    setFormState({...formState, amount, total, vat, items});
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={row.discount}
                                  onChange={(e) => {
                                    let total = 0, amount = 0, vat = 0;
                                    const items = [...formState.items];

                                    if (e.target.value !== '') {
                                      const discount = parseFloat(e.target.value) / 100;
                                      items[i].discount = e.target.value;
                                      const total = items[i].quantity * items[i].unit_cost
                                      const discountAmount = total * discount;  
                                      items[i].amount = total - discountAmount;                          
                                    } else {
                                      items[i].discount = e.target.value;
                                      items[i].amount = items[i].quantity * items[i].unit_cost;
                                    }
                                    items.forEach(item => {
                                      amount += item.amount
                                      if (item.taxable) {
                                        vat += item.amount * environmentConfig.tax / 100;
                                      }
                                    });
                                    total = parseFloat(vat) + parseFloat(amount);

                                    setFormState({...formState, amount, total, vat, items});
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={row.amount}
                                  
                                  readOnly
                                />
                              </td>
                              <td>
                                {i === 0 ? 
                                  <Link
                                  to="#"
                                  className="text-success font-18"
                                  title="Add"
                                  id="addProduct"
                                  onClick={addRow}
                                >
                                  <i className="fa-solid fa-plus" />
                                </Link>
                                :
                                  <Link
                                    className="text-danger font-18 remove"
                                    title="Remove"
                                    onClick={() => removeRow(i)}
                                  >
                                    <i className="fa-regular fa-trash-can" />
                                  </Link>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover table-white">
                        <tbody>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td className="text-end">Sub Total</td>
                            <td className="text-end pe-4">{formatNumber(formState.amount)}</td>
                          </tr>
                          <tr>
                            <td colSpan={5} className="text-end">
                              Tax
                            </td>
                            <td className="text-end pe-4">
                              <input
                              className="form-control text-end"
                              type="number"
                              value={formState.vat}
                              onChange={(e) => {
                                const vat = e.target.value;
                                let total = 0
                                if (vat != '') {
                                  total = formState.amount + parseFloat(vat)
                                } else {
                                  total = formState.amount
                                }
                                setFormState({...formState, vat, total});
                              }}
                            />
                            </td>
                          </tr>
                          {/* <tr>
                            <td colSpan={5} className="text-end">
                              Prepaid Balance
                            </td>
                            <td className="text-end pe-4">
                              <input
                                className="form-control text-end"
                                type="number"
                                value={formState.prepaidBalance}
                                onChange={(e) => setFormState({...formState, prepaidBalance: e.target.value})}
                              />
                            </td>
                          </tr> */}
                          <tr>
                            <td colSpan={5} className="text-end">
                              Amount Paid
                            </td>
                            <td className="text-end pe-4">
                              <input
                                className="form-control text-end"
                                type="number"
                                value={formState.amountPaid}
                                onChange={(e) => setFormState({...formState, amountPaid: e.target.value})}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} className="text-end">
                              Amount to Pay
                            </td>
                            <td className="text-end pe-4">
                              <input
                                className="form-control text-end"
                                type="number"
                                value={formState.total}
                                onChange={(e) => setFormState({...formState, total: e.target.value})}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} className="text-end pe-4">
                              <b>Grand Total</b>
                            </td>
                            <td className="text-end tdata-width pe-4">
                              <b>{formatNumber(formState.total)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Other Information
                          </label>
                          <textarea
                            className="form-control"
                            rows={2}
                            value={formState.other_info}
                            onChange={(e)=> setFormState({...formState, other_info: e.target.value})}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button 
                    className="btn btn-default m-r-10"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary submit-btn"
                    onClick={submit}
                    disabled={isLoading}
                    type="reset"
                  >
                    {isLoading ?
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      />Submitting...
                    </>
                    :
                      'Submit' 
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Deduction Modal */}
      </div>
    </>
  );
};

export default GenerateInvoiceModal;
