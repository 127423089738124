import React from "react";
import { formatNumber, pad } from "../../utils";

const ViewInvoicePaidListModal = ({invoices, close, type}) => {
  return (
    <>
      <div>
        <div
          id="invoice_list"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{type === 'paid' ? 'Total Paid' : 'Outstanding'} List</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  {type === 'outstanding' ? 
                    <table className="table">
                      <thead>
                        <tr>
                        <th>Invoice #</th>
                        <th>Customer</th>
                        <th>Total Amount</th>
                        <th>Amount Paid</th>
                        <th>Outstanding</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices?.map(invoice => (
                          <tr key={invoice.id}>
                            <td>#{pad(invoice.invoice_id, 4)}</td>
                            <td>{invoice.customer.company_name}</td>
                            <td>{formatNumber(invoice.total)}</td>
                            <td>{formatNumber(invoice.total - invoice.outstanding_balance)}</td>
                            <td>{formatNumber(invoice.outstanding_balance)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  :
                    <table className="table">
                      <thead>
                        <tr>
                        <th>Invoice #</th>
                        <th>Customer</th>
                        <th>Total Amount</th>
                        <th>Amount Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices && invoices?.map(row => (
                          <tr key={row.id}>
                            <td>#{pad(row?.invoice?.invoice_id, 4)}</td>
                            <td>{row?.customer?.company_name}</td>
                            <td>{formatNumber(row?.invoice?.total)}</td>
                            <td>{formatNumber(row?.amount)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewInvoicePaidListModal;
